img {
    pointer-events: none;
}

a[href]{text-decoration: underline;}

.page-section {
    padding: 8rem 0;
}

.border{border:1px solid black;}

html, body { min-height: 100%; }
body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    background-color: black;
    color: whitesmoke;
}


mat-card{
    padding: 0px !important;
    max-width: 300px
}

mat-card-title{
    font-size: 20px;
    background-color: grey;
    padding: 15px;
}

mat-card-content{padding: 15px;}